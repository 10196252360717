import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { AbstractTuiPortalService, AbstractTuiPortalHostComponent } from '@taiga-ui/cdk/abstract';

/**
 * Service for displaying dropdown portals
 */
const _c0 = ["*"];
class TuiDropdownPortalService extends AbstractTuiPortalService {}
TuiDropdownPortalService.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiDropdownPortalService_BaseFactory;
  return function TuiDropdownPortalService_Factory(t) {
    return (ɵTuiDropdownPortalService_BaseFactory || (ɵTuiDropdownPortalService_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownPortalService)))(t || TuiDropdownPortalService);
  };
})();
TuiDropdownPortalService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiDropdownPortalService,
  factory: TuiDropdownPortalService.ɵfac,
  providedIn: `root`
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownPortalService, [{
    type: Injectable,
    args: [{
      providedIn: `root`
    }]
  }], null, null);
})();

/**
 * Host element for dynamically created portals, for example using {@link TuiDropdownDirective}.
 */
class TuiDropdownHostComponent extends AbstractTuiPortalHostComponent {}
TuiDropdownHostComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiDropdownHostComponent_BaseFactory;
  return function TuiDropdownHostComponent_Factory(t) {
    return (ɵTuiDropdownHostComponent_BaseFactory || (ɵTuiDropdownHostComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownHostComponent)))(t || TuiDropdownHostComponent);
  };
})();
TuiDropdownHostComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiDropdownHostComponent,
  selectors: [["tui-dropdown-host"]],
  features: [i0.ɵɵProvidersFeature([{
    provide: AbstractTuiPortalService,
    useExisting: TuiDropdownPortalService
  }, {
    provide: AbstractTuiPortalHostComponent,
    useExisting: TuiDropdownHostComponent
  }]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 3,
  vars: 0,
  consts: [["viewContainer", ""]],
  template: function TuiDropdownHostComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
      i0.ɵɵelementContainer(1, null, 0);
    }
  },
  styles: ["[_nghost-%COMP%]{position:relative;z-index:0;display:block;height:100%}[_nghost-%COMP%]:before{content:\"\";display:block;overflow:hidden}.t-position-fixed-offset[_ngcontent-%COMP%]{position:fixed;left:0;top:0;pointer-events:none;visibility:hidden;width:100%;height:100%}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownHostComponent, [{
    type: Component,
    args: [{
      selector: 'tui-dropdown-host',
      templateUrl: './dropdown-host.template.html',
      styleUrls: ['./dropdown-host.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: AbstractTuiPortalService,
        useExisting: TuiDropdownPortalService
      }, {
        provide: AbstractTuiPortalHostComponent,
        useExisting: TuiDropdownHostComponent
      }]
    }]
  }], null, null);
})();
class TuiDropdownHostModule {}
TuiDropdownHostModule.ɵfac = function TuiDropdownHostModule_Factory(t) {
  return new (t || TuiDropdownHostModule)();
};
TuiDropdownHostModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiDropdownHostModule
});
TuiDropdownHostModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownHostModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiDropdownHostComponent],
      exports: [TuiDropdownHostComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDropdownHostComponent, TuiDropdownHostModule, TuiDropdownPortalService };
