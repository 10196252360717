import * as i0 from '@angular/core';
import { ElementRef, Directive, NgZone, Renderer2, Inject, Self, Input, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ANIMATION_FRAME } from '@ng-web-apis/common';
import { tuiTypedFromEvent, tuiPreventDefault, tuiStopPropagation, tuiZonefree, POLLING_TIME, tuiScrollFrom, TuiDestroyService, tuiZoneOptimized, TuiLetModule } from '@taiga-ui/cdk';
import { tuiFadeIn } from '@taiga-ui/core/animations';
import { MODE_PROVIDER } from '@taiga-ui/core/providers';
import { TUI_ELEMENT_REF, TUI_SCROLL_REF, TUI_ANIMATION_OPTIONS, TUI_MODE } from '@taiga-ui/core/tokens';
import { map, switchMap, takeUntil, throttleTime, startWith, distinctUntilChanged } from 'rxjs/operators';
import * as i1$1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i1 from 'rxjs';
import { merge } from 'rxjs';
function TuiScrollControlsComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelement(1, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const bars_r1 = i0.ɵɵnextContext().ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-bar_has-horizontal", bars_r1[1]);
    i0.ɵɵproperty("@tuiFadeIn", ctx_r2.animation);
  }
}
function TuiScrollControlsComponent_ng_container_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelement(1, "div", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const bars_r1 = i0.ɵɵnextContext().ngIf;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-bar_has-vertical", bars_r1[0]);
    i0.ɵɵproperty("@tuiFadeIn", ctx_r3.animation);
  }
}
function TuiScrollControlsComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiScrollControlsComponent_ng_container_0_div_1_Template, 2, 3, "div", 1)(2, TuiScrollControlsComponent_ng_container_0_div_2_Template, 2, 3, "div", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const bars_r1 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", bars_r1[0]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", bars_r1[1]);
  }
}
class TuiScrollbarWrapperDirective {}
TuiScrollbarWrapperDirective.ɵfac = function TuiScrollbarWrapperDirective_Factory(t) {
  return new (t || TuiScrollbarWrapperDirective)();
};
TuiScrollbarWrapperDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiScrollbarWrapperDirective,
  selectors: [["", "tuiScrollbarWrapper", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_ELEMENT_REF,
    useExisting: ElementRef
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbarWrapperDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiScrollbarWrapper]',
      providers: [{
        provide: TUI_ELEMENT_REF,
        useExisting: ElementRef
      }]
    }]
  }], null, null);
})();
const MIN_WIDTH = 24;
class TuiScrollbarDirective {
  constructor(zone, renderer, destroy$, animationFrame$, wrapper, container, doc, el) {
    this.wrapper = wrapper;
    this.container = container;
    this.doc = doc;
    this.el = el;
    this.tuiScrollbar = 'vertical';
    const {
      nativeElement
    } = this.el;
    const mousedown$ = tuiTypedFromEvent(nativeElement, 'mousedown');
    const mousemove$ = tuiTypedFromEvent(this.doc, 'mousemove');
    const mouseup$ = tuiTypedFromEvent(this.doc, 'mouseup');
    const mousedownWrapper$ = tuiTypedFromEvent(this.wrapper.nativeElement, 'mousedown');
    merge(mousedownWrapper$.pipe(tuiPreventDefault(), map(event => this.getScrolled(event, 0.5, 0.5))), mousedown$.pipe(tuiPreventDefault(), tuiStopPropagation(), switchMap(event => {
      const rect = nativeElement.getBoundingClientRect();
      const vertical = getOffsetVertical(event, rect);
      const horizontal = getOffsetHorizontal(event, rect);
      return mousemove$.pipe(map(event => this.getScrolled(event, vertical, horizontal)), takeUntil(mouseup$));
    }))).pipe(tuiZonefree(zone), takeUntil(destroy$)).subscribe(([scrollTop, scrollLeft]) => {
      if (this.tuiScrollbar === 'vertical') {
        renderer.setProperty(this.element, 'scrollTop', scrollTop);
      } else {
        renderer.setProperty(this.element, 'scrollLeft', scrollLeft);
      }
    });
    merge(animationFrame$.pipe(throttleTime(POLLING_TIME)), tuiScrollFrom(this.element)).pipe(tuiZonefree(zone), takeUntil(destroy$)).subscribe(() => {
      if (this.tuiScrollbar === 'vertical') {
        renderer.setStyle(nativeElement, 'top', `${this.thumb * 100}%`);
        renderer.setStyle(nativeElement, 'height', `${this.view * 100}%`);
      } else {
        renderer.setStyle(nativeElement, 'left', `${this.thumb * 100}%`);
        renderer.setStyle(nativeElement, 'width', `${this.view * 100}%`);
      }
    });
  }
  get scrolled() {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth
    } = this.element;
    return this.tuiScrollbar === 'vertical' ? scrollTop / (scrollHeight - clientHeight) : scrollLeft / (scrollWidth - clientWidth);
  }
  get compensation() {
    const {
      clientHeight,
      scrollHeight,
      clientWidth,
      scrollWidth
    } = this.element;
    if (clientHeight * clientHeight / scrollHeight > MIN_WIDTH && this.tuiScrollbar === 'vertical' || clientWidth * clientWidth / scrollWidth > MIN_WIDTH && this.tuiScrollbar === 'horizontal') {
      return 0;
    }
    return this.tuiScrollbar === 'vertical' ? MIN_WIDTH / clientHeight : MIN_WIDTH / clientWidth;
  }
  get thumb() {
    const compensation = this.compensation || this.view;
    return this.scrolled * (1 - compensation);
  }
  get view() {
    const {
      clientHeight,
      scrollHeight,
      clientWidth,
      scrollWidth
    } = this.element;
    return this.tuiScrollbar === 'vertical' ? Math.ceil(clientHeight / scrollHeight * 100) / 100 : Math.ceil(clientWidth / scrollWidth * 100) / 100;
  }
  get element() {
    return this.container.nativeElement;
  }
  getScrolled({
    clientY,
    clientX
  }, offsetVertical, offsetHorizontal) {
    const {
      offsetHeight,
      offsetWidth
    } = this.el.nativeElement;
    const {
      top,
      left,
      width,
      height
    } = this.wrapper.nativeElement.getBoundingClientRect();
    const maxTop = this.element.scrollHeight - height;
    const maxLeft = this.element.scrollWidth - width;
    const scrolledTop = (clientY - top - offsetHeight * offsetVertical) / (height - offsetHeight);
    const scrolledLeft = (clientX - left - offsetWidth * offsetHorizontal) / (width - offsetWidth);
    return [maxTop * scrolledTop, maxLeft * scrolledLeft];
  }
}
TuiScrollbarDirective.ɵfac = function TuiScrollbarDirective_Factory(t) {
  return new (t || TuiScrollbarDirective)(i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(Renderer2), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(ANIMATION_FRAME), i0.ɵɵdirectiveInject(TUI_ELEMENT_REF), i0.ɵɵdirectiveInject(TUI_SCROLL_REF), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(ElementRef));
};
TuiScrollbarDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiScrollbarDirective,
  selectors: [["", "tuiScrollbar", ""]],
  inputs: {
    tuiScrollbar: "tuiScrollbar"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbarDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiScrollbar]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i0.Renderer2,
      decorators: [{
        type: Inject,
        args: [Renderer2]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [TUI_ELEMENT_REF]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [TUI_SCROLL_REF]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    tuiScrollbar: [{
      type: Input
    }]
  });
})();
function getOffsetVertical({
  clientY
}, {
  top,
  height
}) {
  return (clientY - top) / height;
}
function getOffsetHorizontal({
  clientX
}, {
  left,
  width
}) {
  return (clientX - left) / width;
}
class TuiScrollControlsComponent {
  constructor(animation, zone, scrollRef, animationFrame$, mode$) {
    this.animation = animation;
    this.zone = zone;
    this.scrollRef = scrollRef;
    this.animationFrame$ = animationFrame$;
    this.mode$ = mode$;
    this.refresh$ = this.animationFrame$.pipe(throttleTime(300), map(() => this.scrollbars), startWith([false, false]), distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]), tuiZoneOptimized(this.zone));
  }
  get scrollbars() {
    const {
      clientHeight,
      scrollHeight,
      clientWidth,
      scrollWidth
    } = this.scrollRef.nativeElement;
    return [Math.ceil(clientHeight / scrollHeight * 100) < 100, Math.ceil(clientWidth / scrollWidth * 100) < 100];
  }
}
TuiScrollControlsComponent.ɵfac = function TuiScrollControlsComponent_Factory(t) {
  return new (t || TuiScrollControlsComponent)(i0.ɵɵdirectiveInject(TUI_ANIMATION_OPTIONS), i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(TUI_SCROLL_REF), i0.ɵɵdirectiveInject(ANIMATION_FRAME), i0.ɵɵdirectiveInject(TUI_MODE));
};
TuiScrollControlsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiScrollControlsComponent,
  selectors: [["tui-scroll-controls"]],
  hostBindings: function TuiScrollControlsComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("$.data-mode.attr", function TuiScrollControlsComponent___data_mode_attr_HostBindingHandler() {
        return ctx.mode$;
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([MODE_PROVIDER])],
  decls: 2,
  vars: 3,
  consts: [[4, "ngIf"], ["tuiScrollbarWrapper", "", "class", "t-bar t-bar_vertical", 3, "t-bar_has-horizontal", 4, "ngIf"], ["tuiScrollbarWrapper", "", "class", "t-bar t-bar_horizontal", 3, "t-bar_has-vertical", 4, "ngIf"], ["tuiScrollbarWrapper", "", 1, "t-bar", "t-bar_vertical"], ["tuiScrollbar", "vertical", 1, "t-thumb"], ["tuiScrollbarWrapper", "", 1, "t-bar", "t-bar_horizontal"], ["tuiScrollbar", "horizontal", 1, "t-thumb"]],
  template: function TuiScrollControlsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiScrollControlsComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.refresh$));
    }
  },
  dependencies: [i1$1.NgIf, TuiScrollbarWrapperDirective, TuiScrollbarDirective, i1$1.AsyncPipe],
  styles: ["[_nghost-%COMP%]{position:-webkit-sticky;position:sticky;top:0;left:0;z-index:1;min-width:calc(100% - 1px);min-height:calc(100% - 1px);max-width:calc(100% - 1px);max-height:calc(100% - 1px);float:left;-webkit-margin-end:calc(-100% + 1px);margin-inline-end:calc(-100% + 1px);pointer-events:none}.t-bar[_ngcontent-%COMP%]{position:absolute;right:0;bottom:0;pointer-events:auto}.t-bar_vertical[_ngcontent-%COMP%]{top:0;width:.875rem}.t-bar_horizontal[_ngcontent-%COMP%]{left:0;height:.875rem}.t-bar_has-horizontal[_ngcontent-%COMP%]{bottom:.5rem}.t-bar_has-vertical[_ngcontent-%COMP%]{right:.5rem}.t-thumb[_ngcontent-%COMP%]{transition-property:all;transition-duration:.15s;transition-timing-function:ease-in-out;position:absolute;border-radius:6.25rem;border:.25rem solid transparent;cursor:pointer;pointer-events:auto;background:var(--tui-text-01);background-clip:content-box;box-sizing:border-box;transition-property:width,height;opacity:.2}.t-thumb[_ngcontent-%COMP%]:hover{opacity:.24}.t-thumb[_ngcontent-%COMP%]:active{opacity:.48}[data-mode=onDark][_nghost-%COMP%]   .t-thumb[_ngcontent-%COMP%]{background-color:var(--tui-text-01-night)}.t-bar_vertical[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]{right:0;width:.75rem;min-height:1.25rem}.t-bar_vertical[_ngcontent-%COMP%]:hover   .t-thumb[_ngcontent-%COMP%], .t-bar_vertical[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]:active{width:.875rem}.t-bar_horizontal[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]{bottom:0;height:.75rem;min-width:1.25rem}.t-bar_horizontal[_ngcontent-%COMP%]:hover   .t-thumb[_ngcontent-%COMP%], .t-bar_horizontal[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]:active{height:.875rem}"],
  data: {
    animation: [tuiFadeIn]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollControlsComponent, [{
    type: Component,
    args: [{
      selector: 'tui-scroll-controls',
      templateUrl: './scroll-controls.template.html',
      styleUrls: ['./scroll-controls.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiFadeIn],
      providers: [MODE_PROVIDER],
      host: {
        '($.data-mode.attr)': 'mode$'
      }
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ANIMATION_OPTIONS]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [TUI_SCROLL_REF]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }];
  }, null);
})();
class TuiScrollControlsModule {}
TuiScrollControlsModule.ɵfac = function TuiScrollControlsModule_Factory(t) {
  return new (t || TuiScrollControlsModule)();
};
TuiScrollControlsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiScrollControlsModule
});
TuiScrollControlsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiLetModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollControlsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiLetModule],
      declarations: [TuiScrollbarDirective, TuiScrollbarWrapperDirective, TuiScrollControlsComponent],
      exports: [TuiScrollControlsComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiScrollControlsComponent, TuiScrollControlsModule, TuiScrollbarDirective };
